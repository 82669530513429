import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const ReadUser = () => {
    const location = useLocation();

    const user = location.state;
    console.log(user)

    return (
        <div className='bg-background_color w-full h-dvh flex justify-center overflow-hidden pt-20'>
            <div className='bg-background_color w-full h-dvh overflow-hidden px-2 md:w-[50%] lg:w-[40%] xl:w-[35%]' dir='rtl'>

                <div className='navbar createfrom flex items-center' dir='ltr'>

                    <Link className='fixed left-6 top-6 z-50'
                        to={'/'}
                    >
                        <md-filled-icon-button id='backButton_Custom'>
                            <md-icon>keyboard_arrow_left</md-icon>
                        </md-filled-icon-button>

                    </Link>


                    <Navbar
                        titr={' تایید اطلاعات '}
                    />

                </div>

                <div className='scroll_hide body w-full h-full overflow-y-scroll pb-44'>

                    <div className='text-white bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl mt-4 flex items-center justify-center'>{user.name}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.lastName}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.entrance}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.left}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.reservationRoom}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.nationalCode}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.birthday}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.phoneNumber}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.liveCity}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.job}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.education}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.favorite}</div>
                    <div className='bg-input_color text-fontSize_14 p-2 w-full h-14 rounded-2xl text-white mt-4 flex items-center justify-center'>{user.suggestions}</div>

                </div>

            </div>
        </div>

    )
}

export default ReadUser