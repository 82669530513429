import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

//------------------------------------------import_components-------------------------------------------
import OpacityAnimetedPage from './../../components/animation/SetOpacityAnimatedPage';
import { AppContext } from '../../contexts/AppContext';
import { apiURLs } from '../../services/apiURL';
import Navbar from '../../components/Navbar';
import './style/home.css';
//------------------------------------------import_components-------------------------------------------


const Home = () => {

	//------------------------------------------global_state---------------------------------------------
	const { saveFiled, setSaveFiled } = useContext(AppContext);
	//------------------------------------------global_state---------------------------------------------
	const navigate = useNavigate();


	//------------------------------------------hooks----------------------------------------------------
	useEffect(() => {
		const getData = async () => {
			const headres = {
				'accept': 'application/json',
				'Content-Type': 'application/json',
				'ngrok-skip-browser-warning': '1',
			}

			try {
				const {
					data: fetchPassengers,
					status
				} = await axios.get(
					`${apiURLs.serverURL}/passengers`,
					{
						headers: headres
					}
				);

				if (status === 200) {
					setSaveFiled(fetchPassengers);
				} else {
					alert(status);
				}

			} catch (error) {
				alert(error.message);
			}
		}

		if (saveFiled.length === 0) getData();
		else {
			const m = saveFiled;
			setSaveFiled(saveFiled.reverse());
		}

	}, [])

	const handleNavigateToReadUser = (userName) => {
		const user = saveFiled.find(item => item.name === userName);
		navigate('/readUser', { state: user });
	}

	//------------------------------------------hooks----------------------------------------------------


	//------------------------------------------view_component-------------------------------------------
	return (
		<OpacityAnimetedPage motion_div_className={'bg-background_color w-full h-dvh flex justify-center overflow-hidden '}>

			<div className='container h-full md:w-[50%] lg:w-[40%] xl:w-[35%] overflow-hidden pt-24'>

				<Navbar
					titr={' گیلانه جان '}
				/>


				<div className='scroll_hide w-full h-full flex flex-col gap-8 px-10 overflow-y-scroll pb-56 pt-4' dir='rtl'>
					{
						saveFiled.reverse().map((item, index) => (

							<div
								className='w-full h-36 p-2 flex items-center justify-between gap-2 bg-white/30 border border-white rounded-3xl backdrop-blur-3xl shadow-lg'
								key={index}
								onClick={() => handleNavigateToReadUser(item.name)}
							>
								<div className='flex flex-col justify-center gap-8 w-1/2 h-32 text-center rounded-3xl'>

									<div
										className='w-full flex flex-col items-center justify-center text-fontSize_12 line-clamp-3'
									>
										نام نام خانوادگی :
										<p className='flex gap-2 text-fontSize_14 font-bold '>
											<span className='text-card_text'>{item.name}</span>
											<span className='text-card_text'>{item.lastName}</span>
										</p>
									</div>

									<p
										className=' w-full flex flex-col text-fontSize_12 line-clamp-3'
									>
										اتاق رزرو :
										<span className='text-card_text text-fontSize_14 font-bold'>{item.reservationRoom}</span>
									</p>
								</div>

								<div className='flex flex-col justify-center gap-8 w-1/2 h-32 text-center rounded-3xl text-fontSize_12 line-clamp-3'>
									<p
										className=' w-full flex flex-col'
									>
										تاریخ ورود :
										<span className='text-card_text text-fontSize_14 font-bold'>{item.entrance}</span>
									</p>
									<p
										className=' w-full flex flex-col'
									>
										تاریخ خروج :
										<span className='text-card_text text-fontSize_14 font-bold'>{item.left}</span>
									</p>

								</div>

							</div>
						))
					}
				</div>

			</div>
		</OpacityAnimetedPage>
	)
}
//------------------------------------------view_component-------------------------------------------
export default Home;